import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import NewsCard from "../components/news-card";

const BlogPostTemplate = ({ data: { post, site, articles } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "News",
				item: {
					url: `${siteUrl}/news`,
					id: `${siteUrl}/news`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/news/${post.slug}`,
					id: `${siteUrl}/news/${post.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${post.title} | Friends of Eastbourne Hospital`}
				description={post.blogSeo.metaDescription}
				openGraph={{
					url: `${siteUrl}/news/${post.slug}`,
					title: `${post.title} | Friends of Eastbourne Hospital`,
					description: post.blogSeo.metaDescription,
					type: "article",
					images: [
						{
							url: `${post.articleFields.featuredImage.localFile.publicURL}`,
							width: `${post.articleFields.featuredImage.localFile.childImageSharp.original.width}`,
							height: `${post.articleFields.featuredImage.localFile.childImageSharp.original.height}`,
							alt: `${post.articleFields.featuredImage.altText}`,
						},
					],
				}}
			/>
			<section>
				<Container fluid className="px-0 mx-0">
					<Row className="align-items-center bg-light-blue gx-0">
						<Col lg={6}>
							{" "}
							<GatsbyImage
								class="w-100  latest-news-image"
								image={
									post.articleFields.featuredImage?.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={post.articleFields.featuredImage?.localFile.altText}
							/>
						</Col>
						<Col
							className="bg-light-blue px-md-7 px-lg-4 px-xl-7 py-5 px-4"
							lg={6}
						>
							<h1 className="display-4 pb-4 text-primary text-center">
								{post.title}
							</h1>
							{/* <p>
								Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
								diam nonumy eirmod tempor invidunt ut labore et dolore magna.
							</p> */}
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container className=" py-md-7 py-5">
					<Row className="justify-content-center">
						<Col>
							<div className="article-content px-4">{parse(post.content)}</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container className=" pb-7">
					<Row>
						{" "}
						{articles.nodes.map((post) => (
							<NewsCard
								title={post.title}
								excerpt={parse(post.excerpt)}
								uri={post.slug}
								image={
									post.articleFields.featuredImage?.localFile.childImageSharp
										.gatsbyImageData
								}
								imageAlt={post.articleFields.featuredImage.altText}
							/>
						))}
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query ArticleById($id: String!) {
		post: wpArticle(id: { eq: $id }) {
			id
			excerpt
			slug
			blogSeo {
				metaDescription
			}
			content
			title
			dateGmt(formatString: "MMMM DD, YYYY")
			articleFields {
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								quality: 50
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}
		articles: allWpArticle(
			filter: { id: { ne: $id } }
			sort: { fields: dateGmt, order: DESC }
		) {
			nodes {
				id
				title
				excerpt
				slug
				articleFields {
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 50
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
